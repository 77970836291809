const Features = () => {
  return (
    <section id="sobre" className="bg-white mt-10 py-6 sm:py-8 lg:py-12">
      <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
        {/* <!-- text - start --> */}
        <div className="mb-10 md:mb-16">
          <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
            Qualidade e reconhecimento <span className="text-xl">⭐</span>
          </h2>

          <p className="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
            Estamos sempre preparados para te levar a sua melhor versão
            profissional. O mercado de trabalho está exigindo a sua qualidade.
          </p>
        </div>
        {/* <!-- text - end --> */}

        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-12 xl:gap-16">
          {/* <!-- feature - start --> */}
          <div className="flex gap-4 md:gap-6">
            <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-cogyellow-500 text-white rounded-lg md:rounded-xl shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                />
              </svg>
            </div>

            <div>
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Professores 👨‍🏫
              </h3>
              <p className="text-gray-500 mb-2">
                Com os melhores profissionais qualificados para da seu
                treinamento.
              </p>
            </div>
          </div>
          {/* <!-- feature - end --> */}

          {/* <!-- feature - start --> */}
          <div className="flex gap-4 md:gap-6">
            <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-cogyellow-500 text-white rounded-lg md:rounded-xl shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>

            <div>
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Certificado 📃
              </h3>
              <p className="text-gray-500 mb-2">
                Certificado reconhecido em todo território nacional e com
                currículo atualizado.
              </p>
            </div>
          </div>
          {/* <!-- feature - end --> */}

          {/* <!-- feature - start --> */}
          <div className="flex gap-4 md:gap-6">
            <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-cogyellow-500 text-white rounded-lg md:rounded-xl shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </div>

            <div>
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Prática 🛠
              </h3>
              <p className="text-gray-500 mb-2">
                Treinamentos profissionais teóricos e práticos voltados para o
                mercado de trabalho.
              </p>
            </div>
          </div>
          {/* <!-- feature - end --> */}
        </div>
      </div>
    </section>
  )
}

export default Features
