const Article = () => {
  return (
    <section className="bg-white py-6 sm:py-8 lg:py-12">
      <article className="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <div className="bg-gray-100 rounded-lg px-4 py-6 md:py-8 lg:py-12">
          <p className="text-cogblue-500 lg:text-2xl font-semibold text-center mb-2 md:mb-3">
            Time de profissionais vencedores
          </p>

          <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
            5 anos guiando nossos alunos ao sucesso 🏆
          </h2>

          <p className="max-w-screen-md text-gray-600 md:text-xl text-center mx-auto">
            Durante esse tempo nós{' '}
            <span className="font-semibold text-red-500">
              formamos mais de 2000 alunos 👩‍🎓
            </span>{' '}
            prontos para atuarem no mercado de trabalho, seja como profissional
            ou empreendedor. Sempre com foco total na realização desse aluno
            como um profissional que é{' '}
            <span className="font-semibold text-green-600">
              bem remunerado. 💰
            </span>{' '}
            <span className="">Vai ficar de fora dessa?</span>
          </p>
        </div>
      </article>
    </section>
  )
}

export default Article
