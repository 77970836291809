import Head from 'next/head'

interface Props {
  //configure for SEO Itens
  title?: string
  description?: string
  robots?: string
  keys?: string
  canonical?: string
  language?: string
  subjects?: string
  imageUrl?: string
}

export default function Meta({
  title,
  description,
  robots,
  keys,
  canonical,
  language,
  subjects,
  imageUrl
}: Props) {
  return (
    <Head>
      {language && <meta name="language" content={language} />}
      {robots && <meta name="robots" content={robots} />}

      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:site_name" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="twitter:description" content={description} />
        </>
      )}

      {subjects && (
        <>
          <meta property="og:type" content={subjects} />
          <meta property="twitter:card" content={subjects} />
        </>
      )}

      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} />
          <meta property="twitter:image" content={imageUrl} />
        </>
      )}

      {keys && <meta name="keywords" content={keys} />}

      {canonical && (
        <>
          <link rel="canonical" href={canonical} />
          <meta name="og:url" content={canonical} />
          <meta name="twitter:url" content={canonical} />
        </>
      )}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicon-16x16.png"
      />
      <link rel="manifest" href="/images/site.webmanifest" />
      <meta name="msapplication-config" content="/images/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  )
}
