import styled from 'styled-components'
import tw from 'twin.macro'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ICourses } from '../interfaces'

interface ICourseList {
  CoursesList: ICourses[]
}

interface IFormInputs {
  name: string
  whatsapp: string
  courses: string
}

const schema = yup
  .object({
    name: yup.string().required(),
    whatsapp: yup.string().required(),
    courses: yup.string()
  })
  .required()

export default function FormContctInLine({ CoursesList }: ICourseList) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const actionSubmit = async () => {
    try {
      const name = getValues('name')
      const number = getValues('whatsapp')
      const course = getValues('courses')

      await fetch('./api/email', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          number,
          course
        })
      })
      alert(`Recebemos sua mensagem. Aguarde que iremos te chamar em breve`)

      setValue('name', '')
      setValue('whatsapp', '')
    } catch (err) {
      alert(`Ops! Não consegui enviar. Chame direto no Whatsapp (21)96953-3888`)
    }
  }

  return (
    <section id="contato">
      <div className="bg-cogblue-500 py-6">
        <div className="max-w-screen-2xl px-4 lg:px-8 mx-auto">
          <div className="flex flex-col lg:flex-row justify-between items-center gap-2">
            <div className="text-center lg:text-left mb-3 md:mb-0">
              <span className="text-gray-100 font-bold uppercase tracking-widest">
                Chama no Whatsapp ✅
              </span>
              <p className="text-white xl:text-sm 2xl:text-lg">
                Envie seus dados que nós te chamamos 🤩
              </p>
            </div>

            <form
              onSubmit={handleSubmit(actionSubmit)}
              className="w-full flex-col md:flex-row px-4 lg:max-w-2xl xl:max-w-4xl flex gap-2"
            >
              <input
                placeholder="Nome"
                autoComplete="none"
                {...register('name', { required: true })}
                className={`w-full appearance-none m-0 flex-1 bg-white text-gray-600 font-semibold placeholder-gray-400 border  ${
                  errors.whatsapp
                    ? 'border-red-600 ring ring-red-600'
                    : 'border-white ring-cogblue-200'
                } focus:ring rounded outline-none transition duration-100 px-3 py-2`}
              />
              <input
                placeholder="Whatsapp"
                autoComplete="none"
                {...register('whatsapp', { required: true })}
                className={`w-full appearance-none m-0 flex-1 bg-white text-gray-600 font-semibold placeholder-gray-400 border  ${
                  errors.whatsapp
                    ? 'border-red-600 ring ring-red-600'
                    : 'border-white ring-cogblue-200'
                } focus:ring rounded outline-none transition duration-100 px-3 py-2`}
              />

              <select
                {...register('courses')}
                className={`w-full flex-1 bg-white 'text-gray-600' font-semibold placeholder-gray-400 border border-white focus:ring ring-cogblue-200 rounded outline-none transition duration-100 px-3 py-2`}
              >
                <option disabled selected className="text-gray-400">
                  {' '}
                  -- Treinamento --{' '}
                </option>
                {CoursesList.map(course => (
                  <option value={course.courseName}>{course.courseName}</option>
                ))}
              </select>

              <ButtonSubmit type="submit">Me chama</ButtonSubmit>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

const ButtonSubmit = styled.button`
  ${tw`
    inline-block
    px-8
    py-2

    text-sm
    font-semibold
    text-center
    outline-none

    md:text-base

    rounded
    border
    border-transparent

    focus:outline-none
    focus:border-transparent
    focus:opacity-100

    bg-coggreen-500
    text-white

    hover:ring
    hover:border
    hover:border-cogblue-500
    hover:ring-coggreen-400
    active:bg-coggreen-700

    transition
    duration-100
  `}
`
