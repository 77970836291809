import { ICourses, IHeroImages, ILayout, IPages } from '../interfaces'

export const PagesList: IPages[] = [
  {
    id: 'index',
    page: 'Início',
    current: true
  },
  {
    id: 'sobre',
    page: 'Sobre',
    current: false
  },
  {
    id: 'treinamentos',
    page: 'Treinamentos',
    current: false
  },
  {
    id: 'duvidas',
    page: 'Dúvidas',
    current: false
  },
  {
    id: 'contato',
    page: 'Contato',
    current: false
  }
]

export const LayoutData: ILayout[] = [
  {
    page: 'Index',
    title: 'Ensino Cognitivo | Seu futuro profissional começa aqui!',
    description:
      'Centro de formação de profissionais prontos para o mercado de trabalho e para empreender em seus negócios.',
    robots: 'index, follow',
    keys:
      'treinamento, cursos, emprego, mercado de trabalho, profisisonalizante, curso livre, reparo de celular, cuidador de idosos, auxiliar de creche, massoterapia profssional, auxiliar de necropsia',
    canonical: 'https://ensinocognitivo.com.br',
    language: 'pt-BR',
    subjects: 'Educação profissional',
    imageUrl: 'https://ensinocognitivo.com.br/images/svg/slogan.svg',
    pages: PagesList
  }
]

export const CoursesList: ICourses[] = [
  {
    courseId: 'smart',
    courseName: 'Reparo de Celulares',
    title: 'Treinamento teórico e prático',
    score: 4.9,
    imageUrl:
      'https://images.unsplash.com/photo-1576613109753-27804de2cba8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    whatsappLink:
      'https://api.whatsapp.com/send?phone=5521969533888&text=Vim%20pelo%20site%3A%20Quero%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20de%20Reparo%20de%20Celulares.'
  },
  {
    courseId: 'idoso',
    courseName: 'Cuidador de Idosos',
    title: 'Treinamento com estágio',
    score: 4.9,
    imageUrl:
      'https://images.unsplash.com/photo-1542884748-2b87b36c6b90?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    whatsappLink:
      'https://api.whatsapp.com/send?phone=5521969533888&text=Vim%20pelo%20site%3A%20Quero%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20de%20Cuidador%20de%20Idosos'
  },
  {
    courseId: 'massoterapia',
    courseName: 'Massotarapia',
    title: 'Treinamento completo',
    score: 4.9,
    imageUrl:
      'https://images.unsplash.com/photo-1544161515-4ab6ce6db874?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    whatsappLink:
      'https://api.whatsapp.com/send?phone=5521969533888&text=Vim%20pelo%20site%3A%20Quero%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20de%20Massoterapia%20Profissional.'
  },
  {
    courseId: 'necropsia',
    courseName: 'Aux. Necropsia',
    title: 'Treinamento completo',
    score: 4.9,
    imageUrl:
      'https://images.unsplash.com/photo-1603843722974-3a4031f9f97c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80',
    whatsappLink:
      'https://api.whatsapp.com/send?phone=5521969533888&text=Vim%20pelo%20site%3A%20Quero%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20de%20Auxiliar%20de%20Necropsia.'
  },
  {
    courseId: 'creche',
    courseName: 'Aux. de Creche',
    title: 'Treinamento com estágio',
    score: 4.9,
    imageUrl:
      'https://media.istockphoto.com/photos/playing-with-blocks-picture-id863752878?k=20&m=863752878&s=612x612&w=0&h=YSj7JJ2sPBzT_y9SeqdWHI_uV_L5NpDaV-KGvjbONB8=',
    whatsappLink:
      'https://api.whatsapp.com/send?phone=5521969533888&text=Vim%20pelo%20site%3A%20Quero%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20de%20Auxiliar%20de%20Creche.'
  },
  {
    courseId: 'administrativo',
    courseName: 'Aux. Administrativo',
    title: 'Treinamento completo',
    score: 4.9,
    imageUrl:
      'https://images.unsplash.com/photo-1573167691330-597fd91bc6c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1169&q=80',
    whatsappLink:
      'https://api.whatsapp.com/send?phone=5521969533888&text=Vim%20pelo%20site%3A%20Quero%20informa%C3%A7%C3%B5es%20sobre%20o%20curso%20de%20Auxiliar%20Administrativo.'
  }
]

export const HeroImages: IHeroImages[] = [
  {
    image: 'black man student',
    imageUrl:
      'https://images.unsplash.com/photo-1507537509458-b8312d35a233?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80'
  },
  {
    image: ' woman',
    imageUrl:
      'https://images.unsplash.com/photo-1494809610410-160faaed4de0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=688&q=80'
  }
]
