import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IPages } from '../interfaces'

interface IProps {
  pages: IPages[] //use for create a map to link pages
}

const NavBar = ({ pages }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenMenu = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window !== undefined) {
        // browser code
        let scrollY = window.scrollY > 10 ? window.scrollY : 0
        let navDiv = document.getElementById('nav')

        if (!!navDiv) {
          if (scrollY === 0) {
            navDiv.classList.remove('shadow-sm')
          } else {
            navDiv.classList.add('lg:shadow-none')
            navDiv.classList.add('shadow-sm')
          }
        }
      }
    })
  }, [])

  return (
    <div
      className="w-full fixed lg:relative px-4 md:px-8 mx-auto bg-white lg:shadow-none z-10"
      id="nav"
    >
      <div className="max-w-screen-2xl mx-auto  md:px-8 flex justify-between items-center py-4 md:py-4 mb:mb-8">
        {/* Logo */}
        <a
          href="/"
          className="inline-flex items-center text-cogblue-500 text-2xl md:text-3xl font-bold gap-2.5"
          aria-label="logo"
        >
          <img
            src="images/svg/ensino-cognitivo-logo.svg"
            className="h-8 w- md:h-10"
          />
        </a>
        {/* Logo */}
        <nav className="hidden lg:flex gap-12">
          {pages.map(page => (
            <a
              href={`#${page.id}`}
              key={page.id}
              className="text-cogblue-500 text-lg font-semibold"
            >
              {page.page}
            </a>
          ))}
        </nav>
        <a
          href="#contato"
          className="hidden lg:inline-block bg-cogyellow-500 hover:bg-cogyellow-700 focus-visible:ring ring-cogyellow-300 text-white active:text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
        >
          Descontão
        </a>

        <button
          onClick={handleOpenMenu}
          type="button"
          className="inline-flex items-center lg:hidden bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-cogblue-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold rounded-lg gap-2 px-2.5 py-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          Menu
        </button>
      </div>
      <div>
        <Menu isOpen={isOpen}>
          {pages.map(pg => (
            <a
              href={`#${pg.id}`}
              key={pg.id}
              onClick={() => setIsOpen(!isOpen)}
            >
              {pg.page}
            </a>
          ))}
          <div>
            <a href="#contato" onClick={() => setIsOpen(!isOpen)}>
              Descontão
            </a>
          </div>
        </Menu>
      </div>
    </div>
  )
}

export default NavBar

const Menu = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '700px' : '0')};
  transition: max-height 0.3s ease-in-out;
  ${tw`
    lg:hidden

    flex
    flex-col
    justify-between
    align-middle
    relative

    w-full
    px-4
    overflow-hidden
  `}

  a {
    ${tw`
      py-2
      px-4
      text-center
      text-cogblue-500
      font-medium


      cursor-pointer


    `}
  }
  div > a {
    ${tw`
      flex
      justify-center
      py-4
      mb-4

      border-2
      border-cogyellow-500
      rounded-md

      text-cogblue-500
      font-sans
      font-medium

      cursor-pointer

    `}
  }
`
