import { NextPage } from 'next'
import {
  Layout,
  Hero,
  Features,
  Faq,
  Article,
  CardCourses,
  WhatsappFloatButton,
  FormContctInLine
} from '../components'
import { CoursesList, LayoutData } from '../utils/sample-data'

interface IProps {
  isMobile?: boolean
}

const IndexPage: NextPage<IProps> = ({ isMobile }) => {
  const {
    page,
    title,
    pages,
    description,
    robots,
    keys,
    canonical,
    language,
    subjects,
    imageUrl
  } = LayoutData[0]

  return (
    <>
      <Layout
        page={page}
        title={title}
        pages={pages}
        description={description}
        robots={robots}
        keys={keys}
        canonical={canonical}
        language={language}
        subjects={subjects}
        imageUrl={imageUrl}
        childrenHeader={<Hero isMobile={isMobile} />}
      >
        <Features />

        <Article />

        <CardCourses CoursesList={CoursesList} />

        <Faq />

        <FormContctInLine CoursesList={CoursesList} />

        <WhatsappFloatButton isMobile={isMobile} />
      </Layout>
    </>
  )
}

IndexPage.getInitialProps = async ({ req }) => {
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent

  let isMobile = false
  if (!!userAgent) {
    isMobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
  }

  return { isMobile }
}

export default IndexPage
