import styled from 'styled-components'
import tw from 'twin.macro'
import { ICourses } from '../interfaces'

interface ICourseList {
  CoursesList: ICourses[]
}

const CardCourses = ({ CoursesList }: ICourseList) => {
  return (
    <section id="cursos" className="bg-white py-4">
      <article className="max-w-screen-2xl md:px-8 mx-auto">
        <div className="bg-gray-100 lg:rounded-lg px-4 xl:px-18 2xl:px-24 py-6 md:py-8 lg:py-12">
          <div className="mb-10 md:mb-16">
            <h2 className="text-gray-800 whitespace-nowrap text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
              Escolha seu Treinamento<span className="text-xl">👨‍🎓</span>
            </h2>

            <p className="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
              Esse é o momento de escolher o caminho do seu futuro profissional.
              Daqui para frente a sua jornada se inicia. ✍
            </p>
          </div>
          {/* <!-- text - end --> */}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-5">
            {CoursesList.map(course => (
              <div className="relative z-0">
                <div className="px-0 pb-2 bg-white shadow rounded-md max-w-md m-auto">
                  <div className="w-full h-64 md:h-64 bg-gray-200 rounded-sm overflow-hidden">
                    <img
                      src={course.imageUrl}
                      loading="lazy"
                      alt={`curso de ${course.courseName}`}
                      className="w-full h-full object-cover object-center rounded-t-md"
                    />
                  </div>
                  <div className="flex justify-between items-center px-2 pb-2 mt-2">
                    <div className="ml-2">
                      <span className="block text-sm lg:text-xs mb-0 text-gray-600 dark:text-gray-600 font-medium leading-snug">
                        {course.title}
                      </span>
                      <span className="block font-bold text-base leading-snug text-black dark:text-gray-100">
                        {course.courseName}
                      </span>
                      <span className="block align-bottom text-xs text-gray-500 dark:text-gray-400 font-light leading-snug">
                        <span className="bg-white border-0">
                          ⭐⭐⭐⭐⭐ {course.score}/5.0 de avaliação
                        </span>
                      </span>
                    </div>
                    <ButtonCA>saiba mais</ButtonCA>
                  </div>
                </div>
                <a
                  href={`${course.whatsappLink}`}
                  className="absolute h-full w-full top-0 left-0"
                />
              </div>
            ))}
          </div>
        </div>
      </article>
    </section>
  )
}

export default CardCourses

const ButtonCA = styled.a`
  font-size: 0.75rem;
  line-height: 1rem /* 16px */;
  ${tw`
    px-1 py-1 mx-2 lg:mx-0 lg:mr-2 rounded-md border border-gray-400 uppercase text-gray-500 font-normal
  `};
`
