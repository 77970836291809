import { IPages } from '../interfaces'

interface IProps {
  isMobile?: boolean
  pages: IPages[] //use for create a map to link pages
}

const Footer = ({ pages, isMobile }: IProps) => {
  return (
    // <!-- footer - start -->
    <footer className="bg-white">
      {/* <ContactMap /> */}
      <div className="pt-12 lg:pt-16">
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-12 lg:gap-8 mb-16">
            <div className="col-span-full lg:col-span-3">
              {/* <!-- logo - start --> */}
              <div className="hidden lg:block lg:-mt-2 mb-4">
                <a
                  href="/"
                  className="inline-flex items-center text-black-800 text-xl md:text-2xl font-bold gap-2"
                  aria-label="logo"
                >
                  <img
                    src="images/svg/ensino-cognitivo-logo.svg"
                    className="h-8 w- md:h-10"
                  />
                </a>
              </div>
              {/* <!-- logo - end --> */}

              <p className="text-gray-500 sm:pr-8 mb-6">
                Estamos sempre a disposição para melhor te atender. Nos mande um
                "Olá" no WhatsApp ou venha nos visitar. Estamos aguardando você!
                👋
                <br />
                <address className="font-light text-sm py-2">
                  Av. Comendador Teles, 2395 - Vilar dos Teles. São João de
                  Meriti - RJ
                </address>
              </p>

              {/* <!-- social - start --> */}
              <div className="flex gap-4">
                <a
                  href={
                    isMobile
                      ? 'fb://page/184575268374777'
                      : 'http://fb.com/ensinocognitivo'
                  }
                  target="_blank"
                  className="text-facebook-face hover:text-cogblue-200 active:text-cogblue-600 transition duration-100"
                >
                  <svg
                    className="w-7 h-7"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
                  </svg>
                </a>

                <a
                  href={`${
                    isMobile
                      ? 'instagram://user?username=ensinocognitivo'
                      : 'https://www.instagram.com/ensinocognitivo'
                  }`}
                  target="_blank"
                  className="text-facebook-ig hover:text-cogred-200 active:text-cogred-600 transition duration-100"
                >
                  <svg
                    className="w-7 h-7"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>
              </div>
              {/* <!-- social - end --> */}
            </div>

            {/* <!-- nav - start --> */}
            <div className="col-span-full span sm:col-span-1 md:col-span-2">
              <div className="text-gray-800 font-bold tracking-widest uppercase mb-4">
                Menu do site
              </div>

              <div className="flex flex-row md:flex-row gap-5 md:gap-10">
                {pages.map(page => (
                  <div>
                    <a
                      href={`#${page.id}`}
                      key={page.id}
                      className="text-gray-500 hover:text-cogblue-500 active:text-cogblue-600 transition duration-100"
                    >
                      {page.page}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            {/* <!-- nav - end --> */}
          </div>

          <div className="text-gray-400 text-sm text-center border-t py-8">
            © 2021 - Ensino Cognitivo. Todos os direitos reservados.
          </div>
        </div>
      </div>
    </footer>
    // <!-- footer - end -->
  )
}

export default Footer
