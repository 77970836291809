interface IProps {
  isMobile: boolean | undefined
}
const WhatsappFloatButton = ({ isMobile }: IProps) => {
  return (
    <div
      className={`${
        !isMobile ? 'hidden' : '' //Inverse for if isMobile return undefinded is show this component
      } flex fixed justify-around h-12 w-12 m-4 z-10 bottom-0 right-0 shadow-md bg-coggreen-400 rounded-md`}
    >
      <span className="relative inline-flex pl-1">
        <a
          type="button"
          target="_blank"
          href="http://wa.me/5521969533888"
          className="inline-flex justify-center pr-1 items-center text-base leading-6 font-medium  text-white"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="32.000000pt"
            height="32.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#ffff"
              stroke="none"
            >
              <path
                d="M2290 4945 c-1101 -136 -1967 -1020 -2081 -2124 -17 -163 -7 -473 21
-630 43 -247 115 -465 227 -688 l56 -112 -167 -611 c-92 -337 -166 -613 -164
-614 2 -2 282 71 623 161 496 131 625 162 645 154 14 -5 79 -34 145 -64 214
-97 439 -161 676 -193 165 -22 454 -22 614 0 526 74 995 306 1365 676 194 193
323 370 445 611 110 217 188 454 232 704 25 146 25 577 0 730 -43 259 -110
466 -223 695 -323 651 -919 1115 -1632 1270 -222 48 -553 63 -782 35z m625
-414 c287 -53 525 -149 760 -306 402 -269 697 -686 814 -1148 86 -340 79 -726
-18 -1053 -158 -528 -533 -973 -1025 -1213 -286 -140 -550 -201 -871 -201
-368 0 -693 89 -1026 281 l-56 32 -368 -97 c-202 -53 -370 -95 -371 -93 -2 2
39 160 92 352 52 192 96 356 96 364 1 9 -25 59 -57 111 -472 768 -348 1780
295 2420 320 318 726 514 1180 570 123 15 429 4 555 -19z"
              />
              <path
                d="M1641 3658 c-57 -28 -163 -153 -207 -245 -87 -180 -85 -408 5 -618
110 -259 399 -627 684 -871 200 -171 367 -272 612 -368 251 -98 299 -109 465
-109 131 0 152 2 212 25 100 38 175 85 249 158 58 57 70 75 89 135 23 76 37
197 26 239 -5 22 -34 40 -176 111 -260 130 -365 175 -409 175 -37 0 -43 -4
-87 -62 -101 -133 -194 -236 -218 -242 -29 -7 -86 14 -217 80 -213 106 -386
249 -535 440 -81 104 -154 222 -154 250 0 11 38 70 84 130 90 117 116 161 116
194 0 11 -20 66 -43 123 -24 56 -72 172 -107 257 -44 106 -74 165 -96 188
l-32 32 -108 0 c-91 0 -116 -4 -153 -22z"
              />
            </g>
          </svg>
        </a>
        {/* Notfication icon */}
        <span className="flex absolute h-4 w-4 top-0 left-0 -mt-1.5 -ml-1.5 items-center justify-center">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      </span>
    </div>
  )
}

export default WhatsappFloatButton
