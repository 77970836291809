import { ILayout } from '../interfaces'
import { Meta, NavBar, Footer } from '.'

const Layout = ({
  children,
  childrenHeader,
  pages,
  title,
  description,
  robots,
  keys,
  canonical,
  language,
  subjects,
  imageUrl
}: ILayout) => (
  <>
    <Meta
      title={title}
      description={description}
      robots={robots}
      keys={keys}
      canonical={canonical}
      language={language}
      subjects={subjects}
      imageUrl={imageUrl}
    />

    <header id="index">
      <NavBar pages={pages} />
      {childrenHeader}
    </header>

    <main>{children}</main>

    <Footer pages={pages} />
  </>
)

export default Layout
