import { Disclosure } from '@headlessui/react'

const Faq = () => {
  return (
    <section id="duvidas" className="bg-white py-6 sm:py-8 lg:py-12">
      <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
        {/* <!-- text - start --> */}
        <div className="mb-10 md:mb-16">
          <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
            Perguntas frequêntes <span className="text-lg lg:text-xl"> 🙋‍♀️</span>
          </h2>

          <p className="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
            Você ainda está com dúvidas? Vamos aproveitar para responder algumas
            agora. Se a sua dúvida não estiver aqui nos chame pelo campo de
            contato que vamos te responder. 🤗
          </p>
        </div>
        {/* <!-- text - end --> */}
        <div className="flex flex-col max-w-screen-sm border-t mx-auto">
          {/* <!-- question - start --> */}
          <div className="border-b">
            <Disclosure>
              <Disclosure.Button
                as="div"
                className="flex justify-between text-black hover:text-cogblue-500 active:text-cogblue-600 cursor-pointer gap-2 py-4"
              >
                <span className="md:text-lg font-semibold transition duration-100">
                  Como faço para me inscrever? 📝
                </span>

                <span className="text-cogblue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </Disclosure.Button>

              <Disclosure.Panel>
                <p className="text-gray-500 mb-4 transition-transform">
                  As inscrições são feitas presencialmente em nossa unidade
                  física mas você pode fazer um agendamento online através do
                  nosso{' '}
                  <span className="font-semibold text-coggreen-500">
                    Whatsapp (21) 96956-3888.
                  </span>{' '}
                  Para facilitar você pode preencher{' '}
                  <span className="font-semibold text-gray-600">
                    campo de contato
                  </span>{' '}
                  no final desta página.
                </p>
              </Disclosure.Panel>
            </Disclosure>
          </div>
          {/* <!-- question - end --> */}

          {/* <!-- question - start --> */}
          <div className="border-b">
            <Disclosure>
              <Disclosure.Button
                as="div"
                className="flex justify-between text-black hover:text-cogblue-500 active:text-cogblue-600 cursor-pointer gap-2 py-4"
              >
                <span className="md:text-lg font-semibold transition duration-100">
                  Exige escolaridade? 🎓
                </span>

                <span className="text-cogblue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </Disclosure.Button>

              <Disclosure.Panel>
                <p className="text-gray-500 mb-4">
                  Esse é um assunto interessante pois os nossos treinamentos{' '}
                  <span className="font-semibold text-gray-600">
                    não tem exigência de escolaridade mínima.
                  </span>{' '}
                  Mas é bom ficar atento às exigências do mercado de trabalho na
                  área desejada.
                </p>
              </Disclosure.Panel>
            </Disclosure>
          </div>
          {/* <!-- question - end --> */}

          {/* <!-- question - start --> */}
          <div className="border-b">
            <Disclosure>
              <Disclosure.Button
                as="div"
                className="flex justify-between text-black hover:text-cogblue-500 active:text-cogblue-600 cursor-pointer gap-2 py-4"
              >
                <span className="md:text-lg font-semibold transition duration-100">
                  Qual a idade mínima? 🛂
                </span>

                <span className="text-cogblue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </Disclosure.Button>

              <Disclosure.Panel>
                <p className="text-gray-500 mb-4">
                  É ideal que o aluno tenha pelo menos{' '}
                  <span className="font-semibold text-gray-600">
                    16 anos para entender as linguagens técnicas e
                    profissionais.
                  </span>{' '}
                  Caso o aluno tenha menos de 16 anos o responsável pode
                  solicitar um termo de autorização e alguns cursos têm{' '}
                  <span className="font-semibold text-gray-600">
                    visitas técnicas que só podem ser feitas por maiores de 18
                    anos.
                  </span>
                </p>
              </Disclosure.Panel>
            </Disclosure>
          </div>
          {/* <!-- question - end --> */}
        </div>
      </div>
    </section>
  )
}

export default Faq
